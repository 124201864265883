<template>
  <div class="container mb-5">
    <div class="pt-2 font-size-xl mb-3">{{ $t("addresses.edit") }}</div>

    <errors type="validation_errors" />

    <address-form
      @addressSubmited="addressSubmited"
      :allow_submission="allow_submission"
      :address_id="address_id"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AddressForm from "@/views/Addresses/partials/AddressForm.vue";
import Errors from "@/components/Errors.vue";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";
export default {
  components: { Errors, AddressForm },
  name: "AddressesEdit",
  setup() {
    const {t} = useI18n() // use as global scope
    useMeta({
      title: t("addresses.edit"),
    })
  },
  data(){
    return {
      allow_submission: true
    }
  },
  computed: {
    address_id: function () {
      return parseInt(this.$route.params.id);
    },
  },
  methods: {
    ...mapActions(["editAddress"]),
    addressSubmited(address) {
      this.allow_submission = false;
      this.editAddress({ address_id: this.address_id, address: address }).then(
        () => {
          this.$router.push("/addresses");
        }
      ).finally(() => {
        this.allow_submission = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>


