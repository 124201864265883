<template>
  <div>
    <Form @submit="addressSubmited">
      <div class="row">
        <div class="col-md-8">
          <div class="ratio ratio-16_9 w-100 float-left">
            <div class="body">
              <place-search
                v-bind:ready="ready"
                v-bind:gps_timeout="10000"
                v-bind:loading="$t('addresses.map_loading')"
                v-bind:placeholder="$t('addresses.location_search')"
                v-bind:fallbackProcedure="fallbackProcedure"
                v-bind:zoom="zoom"
                v-bind:geolocation="geolocation"
                v-bind:address="address"
                v-bind:manually="manually"
                @changed="getMapData"
              >
              </place-search>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="text-uppercase color-secondary mb-1">
            {{ $t("addresses.title_singular") }}
          </div>
          <div class="mb-3 font-weight-bold">
            {{
              place.country != null
                ? place.country + ", " + place.city
                : $t("addresses.search_before_submit")
            }}
          </div>

          <div class="text-uppercase color-secondary mb-1">
            {{ $t("addresses.fields.zip_code") }}
          </div>
          <div class="mb-3">
            <Field
              name="zip_code"
              type="text"
              v-model="place.zip_code"
              class="form-control"
            />
            <ErrorMessage name="zip_code" />
          </div>

          <div class="text-uppercase color-secondary mb-1">
            {{ $t("addresses.fields.description") }}
          </div>
          <div class="mb-3">
            <Field
              rules="required"
              name="address_description"
              class="form-control"
              v-model="place.address_description"
            >
              <textarea
                name="address_description"
                class="form-control"
                cols="30"
                rows="5"
                v-model="place.address_description"
              ></textarea>
            </Field>
            <ErrorMessage name="address_description" />
          </div>

          <div class="mb-2">
            <div class="form-check">
              <input
                v-bind:disabled="primary_disabled"
                class="form-check-input"
                id="primary_address"
                type="checkbox"
                v-model="primary"
              />
              <label class="form-check-label" for="primary_address">
                {{ $t("addresses.make_primary") }}
              </label>
            </div>
          </div>
          <div class="mt-3">
            <button :disabled='!allow_submission' class="btn btn-primary w-100">
              {{ $t("global.save") }} {{ $t("addresses.title_singular") }}
            </button>
          </div>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import momentTimezone from "moment-timezone";
import { Field, Form, ErrorMessage } from "vee-validate";
export default {
  components: { Field, Form, ErrorMessage },
  name: "AddressesForm",
  props: {
    allow_submission: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
    address_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      ready: undefined,
      primary_disabled: false,
      primary: 0,
      fallbackProcedure: "gps", //gps | geolocation | address
      zoom: 17, //Default Zoom
      geolocation: {
        // If GPS and Find by address fails then, map will be positioned by a default geolocation
        lat: 31.73858,
        lng: -35.98628,
        zoom: 2,
      },
      manually: {},
      address: {
        query: momentTimezone.tz.guess().replace("/", " , "), //If GPS fails, Find by address is triggered
        zoom: 10,
      },
      place: {},
    };
  },
  methods: {
    ...mapActions(["getAddress"]),
    getMapData(place) {
      this.place = place;
    },
    addressSubmited() {
      let address = {
        country: this.place.country,
        state: this.place.state,
        city: this.place.city,
        postal_code:
          this.place.zip_code.length != 0
            ? String(this.place.zip_code)
            : undefined,
        latitude: this.place.lat,
        longitude: this.place.lng,
        address_description: this.place.address_description,
        primary: this.primary,
      };

      this.$emit("addressSubmited", address);
    },
    setCurrentAddress() {
      this.place = {
        country: this.address.country.name,
        city: this.address.city,
        zip_code: this.address.postal_code,
        lat: this.address.latitude,
        lng: this.address.longitude,
        address_description: this.address.address_description,
      };

      this.fallbackProcedure = "manually";

      this.manually = {
        country: this.address.country.name,
        city: this.address.city,
        state: this.address.state,
        zip_code: this.address.postal_code,
        lat: this.address.latitude,
        lng: this.address.longitude,
        address_description: this.address.address_description,
        zoom: 17,
      };

      this.primary = this.address.primary;
      if (this.address.primary == true) {
        this.primary_disabled = true;
      }
      this.ready = true;
    },
  },
  created() {
    //Check it address_id is set
    if (this.address_id != null) {
      this.ready = false;
      this.getAddress(this.address_id).then(
        (address) => {
          this.address = address;
          this.setCurrentAddress();
        },
        () => {
          this.$router.replace("/404");
        }
      );
    }
  },
};
</script>

<style lang="scss" scoped>
</style>


